











































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {TaskModel} from "@/models/TaskModel";
import {NewThreadStore, NewThreadStoreModule} from "@/stores/NewThreadStore";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";
import LoadingTable from "@/components/Atoms/LoadingTable.vue";
import NameById from "@/components/Atoms/NameById.vue";
import {SheetStore, SheetStoreModule} from "../../stores/SheetStore";
import TaskThreadView from "@/components/Templates/TaskThreadView.vue";
import IssueThreadView from "../Templates/IssueThreadView.vue";
import ResponseLogThreadView from "../Templates/ResponseLogThreadView.vue";
import {IssueModel} from "../../models/IssueModel";
import {ResponseLogModel} from "../../models/ResponseLogModel";
import axios from "axios";
import {Task} from "../../models/interfaces/Task";
import {Issue} from "../../models/interfaces/Issue";
import {ResponseLog} from "../../models/interfaces/ResponseLog";

@Component({
    components: {
        LoadingTable,
        TaskThreadView,
        IssueThreadView,
        ResponseLogThreadView,
        NameById
    }
})
export default class DashBoardPage extends Vue {
    private isLoadedTaskThread = false;
    private isLoadedIssueThread = false;
    private isLoadedResponseLogThread = false;

    private taskMap = new Map<number, TaskModel>();
    private issueMap = new Map<number, IssueModel>();
    private responseLogMap = new Map<number, ResponseLogModel>();

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    private get newThreadStore(): NewThreadStore {
        return NewThreadStoreModule;
    }

    private async created(): Promise<void> {
        await this.newThreadStore.fetchNewThread(this.sheetStore.currentSheetId ? this.sheetStore.currentSheetId : 0);
        this.isLoadedTaskThread = true;
        this.isLoadedIssueThread = true;
        this.isLoadedResponseLogThread = true;
    }

    private async fetchTask(taskId: number): Promise<void> {
        const response = await axios.get<Task>("api/task/" + taskId);
        this.taskMap.set(taskId, new TaskModel(response.data, {}));
    }

    private async fetchIssue(issueId: number): Promise<void> {
        const response = await axios.get<Issue>("api/issue/" + issueId);
        this.issueMap.set(issueId, new IssueModel(response.data));
    }

    private async fetchResponseLog(responseLogId: number): Promise<void> {
        const response = await axios.get<ResponseLog>("api/response_log/" + responseLogId);
        this.responseLogMap.set(responseLogId, new ResponseLogModel(response.data));
    }

    private async showTaskThread(taskId: number): Promise<void> {
        const taskThreadView = this.$refs.taskThreadView as TaskThreadView;
        if (taskThreadView) {
            if (!this.taskMap.has(taskId)) {
                await this.fetchTask(taskId);
            }

            let task = this.taskMap.get(taskId);
            if (!task) {
                task = new TaskModel({id: taskId}, {});
            }

            await taskThreadView.showAsync(task);
            await this.created();
        }
    }

    private async showIssueThread(issueId: number): Promise<void> {
        const issueThreadView = this.$refs.issueThreadView as IssueThreadView;
        if (issueThreadView) {
            if (!this.issueMap.has(issueId)) {
                await this.fetchIssue(issueId);
            }

            let issue = this.issueMap.get(issueId);
            if (!issue) {
                issue = new IssueModel({issueId});
            }

            await issueThreadView.showAsync(issue);
            await this.created();
        }
    }

    private async showResponseLogThread(responseLogId: number): Promise<void> {
        const responseLogThreadView = this.$refs.responseLogThreadView as ResponseLogThreadView;
        if (responseLogThreadView) {
            if (!this.responseLogMap.has(responseLogId)) {
                await this.fetchResponseLog(responseLogId);
            }

            let responseLog = this.responseLogMap.get(responseLogId);
            if (!responseLog) {
                responseLog = new ResponseLogModel({responseLogId});
            }

            await responseLogThreadView.showAsync(responseLog);
            await this.created();
        }
    }
}
