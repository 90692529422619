import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "../store";
import {NewTaskThread} from "@/models/interfaces/NewTaskThread";
import {NewThread} from "@/models/interfaces/NewThread";
import {NewIssueThread} from "@/models/interfaces/NewIssueThread";
import {NewResponseLogThread} from "@/models/interfaces/NewResponseLogThread";

/**
 * @summary タスク情報に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "newTaskThread", namespaced: true})
export class NewThreadStore extends VuexModule {
    /** 新着スレッドリスト */
    private _newTaskThreadList: NewTaskThread[] = [];

    /** 新着エラー報告スレッドリスト */
    private _newIssueThreadList: NewIssueThread[] = [];

    /** 新着対応ログスレッドリスト */
    private _newResponseLogThreadList: NewResponseLogThread[] = [];

    /**
     * @summary 新着タスクスレッドリストを取得する
     */
    public get newTaskThreadList(): NewTaskThread[] {
        return this._newTaskThreadList;
    }

    /**
     * @summary 新着エラー報告スレッドリストを取得する
     */
    public get newIssueThreadList(): NewIssueThread[] {
        return this._newIssueThreadList;
    }

    /**
     * @summary 新着対応ログスレッドリストを取得する
     */
    public get newResponseLogThreadList(): NewResponseLogThread[] {
        return this._newResponseLogThreadList;
    }

    /**
     * @summary 新着タスクスレッドリストを初期化する
     */
    @Mutation
    public initNewTaskThreadList(newTaskThreadList: NewTaskThread[]): void {
        this._newTaskThreadList = newTaskThreadList;
    }

    /**
     * @summary 新着エラー報告スレッドリストを初期化する
     */
    @Mutation
    public initNewIssueThreadList(newIssueThreadList: NewIssueThread[]): void {
        this._newIssueThreadList = newIssueThreadList;
    }

    /**
     * @summary 新着対応ログスレッドリストを初期化する
     */
    @Mutation
    public initNewResponseLogThreadList(newResponseLogThreadList: NewResponseLogThread[]): void {
        this._newResponseLogThreadList = newResponseLogThreadList;
    }

    /**
     * @summary 新着スレッドをサーバーから取得する
     */
    @Action
    public async fetchNewThread(sheetId: number): Promise<void> {
        try {
            const response = await axios.get<NewThread>(`api/dashboard?sheetId=${sheetId}`);
            this.initNewTaskThreadList(response.data.newTaskThreadList);
            this.initNewIssueThreadList(response.data.newIssueThreadList);
            this.initNewResponseLogThreadList(response.data.newResponseLogThreadList);
        }
        catch (ex) {
            console.error("新着スレッドの取り込みに失敗しました.", ex);
        }
    }
}

export const NewThreadStoreModule = getModule(NewThreadStore);
