














import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class LoadingTable extends Vue {
    @Prop({default: true}) readonly useHeader!: boolean;
    @Prop({default: 5}) readonly row!: number;
    @Prop({default: 1}) readonly col!: number;

    @Prop({default: undefined}) readonly elevation!: number | string;
    @Prop({default: undefined}) readonly height!: number | string;

    @Prop({default: "heading@1, table-row-divider@3"}) readonly loaderType!: string;

    @Prop({default: "grey"}) readonly headerColor!: string;
    @Prop({default: 2}) readonly headerLighten!: number;

    @Prop({default: "grey"}) readonly tableColor!: string;
    @Prop({default: 3}) readonly tableLighten!: number;

    @Prop({default: ""}) readonly headerClass!: string;
    @Prop({default: ""}) readonly bodyClass!: string;
    @Prop({default: ""}) readonly headerStyle!: string;
    @Prop({default: ""}) readonly bodyStyle!: string;

    private getHeaderColor(): string {
        if (!this.headerColor) return "";

        if (this.headerLighten > 0) return this.headerColor + " lighten-" + this.headerLighten;

        return this.headerColor;
    }

    private getBodyColor(): string {
        if (!this.tableColor) return "";

        if (this.tableLighten > 0) return this.tableColor + " lighten-" + this.tableLighten;

        return this.tableColor;
    }
}
